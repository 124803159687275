<template>
  <div id="flightList" class="Content-Page">
    <div id="namePage" class="wrap-Main">
      <div class="box-S4 flex-between-center">
        <div class="N-Page T-size-36">RFID Scanner</div>
      </div>
    </div>

    <div id="flightDetail" class="wrap-Main" v-if="userdata.r_id == 5">
      <div class="box-S4">
        <div class="box-S4">
          <div class="B-carddetail B-formMain">
            <div class="b-row">
              <div class="box-S4">
                <v-select
                  class="hideMessage"
                  :items="itemsListChannel"
                  item-text="cn_name"
                  item-value="cn_id"
                  label="Channel"
                  v-model="channel"
                  return-object
                  :disabled="userdata.r_id != 5"
                ></v-select>
                <!-- <v-text-field label="foobar_x" ref="foobar_x"></v-text-field> -->

                <audio id="audio-player" allow="autoplay">
                  <source
                    src="../../public/mp3/Button_09.wav"
                    type="audio/mpeg"
                  />
                  Your browser does not support the audio tag.
                </audio>
                <!-- <v-btn @click="toggleAudio()">TEST</v-btn> -->
              </div>
            </div>

            <!-- Dialog Input RFID -->
            <v-dialog v-model="dialogInputRFID" persistent max-width="400">
              <v-card id="dialog" class="TF type2">
                <div class="b-content">
                  <div class="B-dialogform">
                    <div class="t-namepage">
                      RFID <small>{{ p_id }} {{ p_fullname }}</small>
                    </div>
                    <div class="b-form">
                      <v-text-field
                        id="txtRFID"
                        label="RFID Code"
                        v-model="p_rfid"
                        ref="rfid"
                        v-on:keyup.enter="UpdateRFID()"
                      ></v-text-field>
                      <input type="text" ref="searchbar" />
                    </div>
                  </div>
                  <div class="box-btn">
                    <v-btn
                      class="theme-btn-even btn-cancel Bsize100"
                      text
                      @click="CancelSaveRFID()"
                    >
                      <span class="T-size-18">cancel</span>
                    </v-btn>
                  </div>
                </div>
              </v-card>
            </v-dialog>
            <!-- Dialog Input RFID -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import feathersClientUOA from "../plugins/feathers-client-uoa";

export default {
  data: () => ({
    //Add
    userdata: "",
    itemsListChannel: [
      { cn_id: 1, cn_name: "Channel 1" },
      { cn_id: 2, cn_name: "Channel 2" },
    ],
    dialogInputRFID: false,
    p_rfid: "",
    p_id: "",
    p_fullname: "",

    myTimeout: null,
  }),
  components: {},
  async mounted() {
    this.userdata = await this.$CurrentUser.GetCurrentUser();
    this.channel = {
      cn_id: this.itemsListChannel[0].cn_id,
      cn_name: this.itemsListChannel[0].cn_name0,
    };

    await feathersClientUOA
      .service("passenger")
      .on("patched", async (passenger) => {
        if (passenger.p_rfid_scanner == this.channel.cn_id) {
          this.p_rfid = "";
          const q = {};
          q.u_id = passenger.u_id;
          let users = await feathersClientUOA
            .service("user")
            .find({ query: q });
          let user = users.data[0];
          this.p_id = passenger.p_id;
          this.p_fullname =
            user.u_tital + " " + user.u_firstname + "  " + user.u_lastname;
          this.myTimeout = setTimeout(() => {
            this.$refs.rfid.focus();
          }, 2);

          this.toggleAudio();
          this.dialogInputRFID = true;
        }
      });
  },
  methods: {
    toggleAudio() {
      var audio = document.getElementById("audio-player");
      if (audio != {} && audio != null) {
        if (audio.paused) {
          audio.play();
        } else {
          audio.pause();
        }
      }
    },
    CancelSaveRFID() {
      this.myStopTimeout();
      this.dialogInputRFID = false;
      this.p_rfid = null;
    },

    async UpdateRFID() {
      this.myStopTimeout();
      let dataUpdate = {
        p_rfid: this.p_rfid,
      };
      try {
        await feathersClientUOA
          .service("passenger")
          .patch(this.p_id, dataUpdate);
        await feathersClientUOA
          .service("clear-channel-rfid-scanner")
          .patch(this.channel.cn_id, {});
        this.dialogInputRFID = false;
      } catch (error) {
        alert("catch" + error);
        console.log(error);
      }
    },

    myStopTimeout() {
      clearTimeout(this.myTimeout);
    },
  },
};
</script>

<style>
</style>